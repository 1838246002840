<template>
  <div style="min-height: 70dvh; position: relative">
    <img
      src="@/assets/home-green.svg"
      style="width: 100%; height: 100%; object-fit: cover; position: absolute"
      @click="$router.push({ name: 'home' })" />
    <v-container
      style="
        position: relative;
        min-height: 70dvh;
        display: flex;
        align-items: center;
      ">
      <v-row style="align-items: stretch">
        <v-col cols="12" md="4">
          <AppCard
            class="pa-6 link-card text-center text-md-left"
            style="display: flex; flex-direction: column; align-self: stretch">
            <v-flex xs12 class="card-title"> Area amministrazione </v-flex>

            <a @click="login_awb" class="mt-2 md-mt-0">
              <v-btn color="primary" dark :ripple="false" depressed>
                <span class="text-capitalize">Accedi</span>
              </v-btn>
            </a>
          </AppCard>
        </v-col>
        <v-col
          cols="12"
          md="4"
          v-for="(item, j) in items"
          :key="j"
          style="align-self: stretch">
          <AppCard
            class="pa-6 link-card text-center text-md-left"
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
            ">
            <v-flex xs12 class="card-title">
              {{ item.name }}
            </v-flex>
            <router-link :to="item.url" class="mt-2 md-mt-0">
              <v-btn color="primary" dark :ripple="false" depressed>
                <span class="text-capitalize">Accedi</span>
              </v-btn>
            </router-link>
          </AppCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!-- <v-layout wrap justify-start>
    <v-flex xs12>
      <v-layout wrap justify-center style="min-height: 70dvh">
        <v-flex xs12 md9 lg6>
          <v-layout wrap justify-start items-center>
            <v-flex xs12 md4 pa-4>
              <AppCard
                class="pa-6"
                v-for="(item, j) in items"
                :key="j"
                style="display: flex; flex-direction: column">
                <v-flex xs12 class="card-title">
                  {{ item.name }}
                </v-flex>
                <v-flex xs12 py-2 class="normal-text">
                  <span v-html="item.description"></span>
                </v-flex>
                <v-flex xs12 pt-2 style="margin-top: auto">
                  <a :href="item.url" target="_blank">
                    <v-btn color="primary" dark :ripple="false" depressed>
                      <span class="text-capitalize">Accedi al portale</span>
                    </v-btn>
                  </a>
                </v-flex>
              </AppCard>
            </v-flex>
            <v-flex xs12 md4 pa-4>
              <AppCard
                class="pa-6"
                style="display: flex; flex-direction: column">
                <v-flex xs12 class="card-title"> Area amministrazione </v-flex>
                <v-flex xs12 py-2 class="normal-text">
                  <span
                    >Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nesciunt odio, adipisci assumenda suscipit fugit ea
                    consequatur dolorum laudantium
                  </span>
                </v-flex>
                <v-flex xs12 pt-2 style="margin-top: auto">
                  <v-btn
                    color="primary"
                    dark
                    :ripple="false"
                    depressed
                    @click="login_awb">
                    <span class="text-capitalize">Accedi al portale</span>
                  </v-btn>
                </v-flex>
              </AppCard>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout> -->
</template>

<script>
import AppCard from "@/components/shared/AppCard";

export default {
  name: "HomeServices",
  components: {
    AppCard,
  },
  data() {
    return {
      items: [
        {
          name: "Area marketplace",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc",
          url: this.$router.resolve({ name: "products" }).href,
        },
        {
          name: "Area emissioni",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc",
          url: this.$router.resolve({ name: "companies" }).href,
        },
      ],
    };
  },
  methods: {
    async login_awb() {
      const response = await fetch(
        `${this.$store.getters.getBaseUrl}/loginawb`,
        {
          headers: this.$store.getters.getHeaders,
        }
      );
      if (response.ok) {
        const data = await response.json();
        const link = document.createElement("a");
        link.href = data.url;
        link.style.display = "none";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
